<template>
  <div id="app" data-server-rendered="true">
    <router-view />
  </div>
</template>

<script>
  export default {
    name: 'App',
    components: {},
    data() {
      return {};
    },
    computed: {},
    methods: {},
    created() {},
    updated() {},
    mounted() {}
  };
</script>

<style>
</style>
