import { render, staticRenderFns } from "./pageHead.vue?vue&type=template&id=15a6a423&scoped=true&"
import script from "./pageHead.vue?vue&type=script&lang=js&"
export * from "./pageHead.vue?vue&type=script&lang=js&"
import style0 from "./pageHead.vue?vue&type=style&index=0&id=15a6a423&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15a6a423",
  null
  
)

export default component.exports